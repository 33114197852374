import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_@babel+core@7.23.7_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/arbitrage.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/chainlink.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/Chronicle.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/gavel.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/Pyth.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/redstone.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/sandwich.webp");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Oval/Countup.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Oval/FooterOval.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Oval/Hero.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Oval/IntegrateOvalModal/IntegrateOvalModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/OvalBanner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Portal/Portal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/VoteTicker.tsx")